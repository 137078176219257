[data-theme='VIPMEMBER'] {
#app{
  background: var(--background-color);
}
.mb-content.mobile-menu {
  background: var(--menu-mobile-color); 
}
.menu-item .mtitle{
  color: var(--text-color) !important;
}
.menu-item.active .mtitle{
  color: var(--text-very-small) !important;
}
.bookingHistory-main {
  background: var(--background-color);
  .scheduleItem{
    background: var(--card-item-color) !important;
    .text-very-small{
      color: var(--text-very-small);
    }
  }
}
.notificationVipmember{
  background: var(--background-color);
  .cardVipmember{
    background: var(--card-item-color);
    border: none;
    .ntfy{
      background: var(--card-item-color) !important;
    }
    .title-note{
      color: var(--text-color);
    }
    circle{
      fill: var(--background-icon-color);
    }
    .title-note-content{
      color: var(--gray-silver);
    }
  }
  .pagination {
    .anticon-left, .anticon-right{
      color: var(--text-very-small) !important;
    }
    .input_paging{
      background-color: var(--background-icon-color);
    }
  }
  .individual-style{
    .groupCardVipmember{
      background: var(--background-color) !important;
      border: none;
      border-radius: 16px;
    }
    .notification-item{
      background: var(--card-item-color) !important;
      border: none !important;
    }
    .messageContent, .dateNotifiVipmember{
      color: var(--gray-silver);
    }
  }
  .bookingHistoryTabs {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: var(--text-color) !important;
      }
    }
  }
}
.stepBooking-header{
  background: var(--background-color);
}
.booking-service{
  background: var(--background-color);
  .booking-service-item{
    background: var(--card-item-color);
    .subTitle{
      color: var(--text-color) !important;
    }
  }
}
.contactVipmember {
  background: var(--background-color);
}
.bookingVipmember{
  background: var(--background-color);
  .bg-white{
    background: var(--background-color) !important;
    .custom-form .ant-form-item-label label{
      color: var(--text-color);
    }
    .pointer_input {
      background: var(--card-item-color);
      input{
        background-color: var(--card-item-color);
      }
    }
    .ant-form-item .ant-form-item-label{
      background: var(--background-color);
    }
    .ant-select-selector{
      background: var(--card-item-color);
    }
    .ant-picker-suffix{
      color: var(--text-color);
    }
    .ant-form-item-extra {
      background: var(--background-color);
    }
   
  }
  .ant-form-item-required{
    color: var(--text-color);
  }
}
.profileVipmember{
  background: var(--background-color) !important;
  .login__input{
    background: var(--card-item-color);
    color: var(--gray-silver);
  }
  .ant-form-item-required{
    color: var(--text-color);
  }
  .custom-form .ant-form-item-label label{
    color: var(--text-color);
  }
}
.passManagerVipmember{
  background: var(--background-color);
  .ant-form-item-required{
    color: var(--text-color);
  }
  .ant-input{
    background: var(--card-item-color);
  }
  .ant-input-password-icon.anticon{
    color: var(--text-color);
  }
}
.bg-white{
  background: var(--background-color) !important;
  .ant-form-item-required{
    color: var(--text-color);
  }
  .ant-input{
    background: var(--card-item-color) !important;
    color: var(--gray-silver);
  }
  .ant-input-affix-wrapper:hover{
    border: 1px solid var(--primary-button-color);
    .ant-input:hover{
      border: 1px solid var(--primary-button-color);
    }
  }
  .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper{
    background: var(--card-item-color);
    color: var(--text-color);
  }
  .vehicleIdentity{
    .custom-df-btn.light{
      background: var(--primary-button-color) !important;
      span{
        color: var(--text-very-small) !important;
      }
    }
  }
  .card-auto-search{
    span {
      color: var(--gray-silver);
    }
  }
}
.ant-empty-description{
  color: var(--text-color) !important;
}
.kCOvMt{
  background: var(--background-color);
  .search-item{
    .ant-input{
    background: var(--card-item-color);
    color: var(--gray-silver);
    }
    .ant-input:hover{
      border-color: var(--primary-button-color);
    }
    .ant-input-search-button{
      background: var(--card-item-color);
      svg path{
       fill: var(--background-icon-color);
      }
    }
    .ant-input-affix-wrapper{
      background: var(--card-item-color);
    }
    .ant-input-affix-wrapper:hover{
      border-color: 1px solid var(--primary-button-color) !important;
    }
    
  }
}
.disabledlist-station, .disabledlist-station.active{
  background: var(--card-item-color);
  border-bottom: 1px solid var(--primary-button-color);
  .bUBApm{
    color: var(--gray-silver) !important;
  } 
  .hours-working-time , .phone-working-time{
    color: var(--gray-silver);
  }
}
.list-station{
  background: var(--card-item-color);
  .bUBApm{
    color: var(--gray-silver) !important;
  }
  .hours-working-time , .phone-working-time{
    color: var(--gray-silver);
  }
}
.list-station.active{
  background: var(--card-item-color);
}
.list-items{
  background: var(--card-item-color) !important;
  color: var(--text-color);
}
.ant-modal-content{
  background: var(--background-color);
  .ant-modal-body{
    div{
      color: var(--text-color);
    }
  }
  .ant-modal-title{
    color: var(--text-color);
  }
  .ant-modal-header{
    background: var(--background-color);
  }
  .ant-modal-footer{
    background: var(--background-color);
    .btn-ok {
      color: var(--text-color);
    }
  }
  .anticon svg{
    color: var(--primary-button-color);
  }
}
.SearchList {
  .ant-input-group{
    .ant-input{
      background: var(--card-item-color);
      color: var(--gray-silver);
    }
  }
  .ant-input-search-button{
    background: var(--card-item-color);
    svg path{
      fill: var(--background-icon-color);
    }
  }
} 
.custom-form{
  .text-nomal{
    color: var(--text-color);
  }
}
.bg-gray-athens-mobile{
  background: var(--background-color);
  .automatedTrafficFineNotification-body .service-layout-container .service-layout-desc{
    color: var(--text-color);
  }
  .custom-df-btn.disabled{
    background: var(--background-icon-color) !important;
    span{
      color: var(--text-very-small) !important;
    }
  }
  .service-card{
    span{
      color: var(--text-color);
    }
  }
  .stepAutomatedTrafficFineNotification-step .ant-steps-item-title{
    color: var(--text-color) !important;
  }
  .stepAutomatedTrafficFineNotification-icon{
    svg path {
      fill: var(--background-icon-color);
    }
  }
}

}