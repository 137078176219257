$--unnamed-color-ffffff: #FFFFFF;
$--unnamed-color-ffffff: #FFFFFF;
$--unnamed-color-b4b4b4: #B4B4B4;
$--unnamed-color-d6d6d6: #D6D6D6;
$--unnamed-color-007bff: #007BFF;
$--unnamed-color-24242600: #242426;
$--unnamed-color-6b6b6b: #6B6B6B;
$--unnamed-color-7ee010: #7EE010;
$--unnamed-color-356203: #356203;
$--unnamed-color-24242: #242424;
$--unnamed-color-002399: #002399;
$--color-FA541C: #FA541C;
$--color-FFF2E8: #FFF2E8;
$--color-F4F7FF: #F4F7FF;
$--color-EBF7EC: #EBF7EC;
$--color-FFF5CE: #FFF5CE;
$--color-FAAD14: #FAAD14;
$--color-E5FDD1: #E5FDD1;

$--sunrise-yellow-6: #FADB14;
$--blue-primary-9: #003A8C;
$--neutral-7: #8C8C8C;
$--midnight-blue: #051945;
$--gainsboro-9: #F5F5F5;
$--light-grey-color: #d9d9d9;
$--royal-blue-color: #416FF3;
$--slate-blue-color: #6E3DD5;
$--momo-main-color: #d82d8b;


html{
  --main-color: #002399;
  --sub-color: #ffffff;
}

.BLACK{
  --main-color:#242426;
  --sub-color: #ffffff;
}

.BLUE{
  --main-color: #002399;
  --sub-color: #ffffff;
}

.BLUE_SECOND{
  --main-color:: #007BFF;
  --sub-color: #ffffff;
}

.BLUE_THIRD{
  --main-color: #25F7F2;
  --sub-color: #ffffff;
}

.GREEN{
  --main-color: #7EE010;
  --sub-color: #ffffff;
}

.disableBtn{
  pointer-events: none;
  opacity: 0.6;
  cursor: auto;
  color: var(--gray-color) !important;
}

// Định nghĩa kích thước chữ, khoảng cách dòng và độ đậm cho văn bản
$text-styles: (
  'x-very-small': (
    'font-size': 10px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'very-small': (
    'font-size': 12px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  'small': (
    'font-size': 14px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  'normal': (
    'font-size': 16px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  'x-normal': (
    'font-size': 18px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  'large': (
    'font-size': 20px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  'x-large': (
    'font-size': 24px,
    'line-height': 1.5,
    'font-weight': 400
  ),
  '2x-large': (
    'font-size': 28px,
    'line-height': 26px,
    'font-weight': 400
  ),
);

// Định nghĩa kích thước chữ, khoảng cách dòng và độ đậm cho title
$title-styles: (
  'very-small': (
    'font-size': 14px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'small': (
    'font-size': 16px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'small2x': (
    'font-size': 18px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'normal': (
    'font-size': 24px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'normal-bold': (
    'font-size': 24px,
    'line-height': 1.5,
    'font-weight': 500
  ),
  'large': (
    'font-size': 32px,
    'line-height': 1.5,
    'font-weight': 600
  ),
  'x-large': (
    'font-size': 48px,
    'line-height': 1.5,
    'font-weight': 600
  ),
);

// Text styles
$text-x-very-small-fs: map-get(map-get($text-styles, 'x-very-small'), 'font-size'); // 10px
$text-x-very-small-lh: map-get(map-get($text-styles, 'x-very-small'), 'line-height'); // 1.5
$text-x-very-small-fw: map-get(map-get($text-styles, 'x-very-small'), 'font-weight'); // 600

$text-very-small-fs: map-get(map-get($text-styles, 'very-small'), 'font-size'); // 12px
$text-very-small-lh: map-get(map-get($text-styles, 'very-small'), 'line-height'); // 1.5
$text-very-small-fw: map-get(map-get($text-styles, 'very-small'), 'font-weight'); // 400

$text-small-fs: map-get(map-get($text-styles, 'small'), 'font-size'); // 14px
$text-small-lh: map-get(map-get($text-styles, 'small'), 'line-height'); // 1.5
$text-small-fw: map-get(map-get($text-styles, 'small'), 'font-weight'); // 400

$text-normal-fs: map-get(map-get($text-styles, 'normal'), 'font-size'); // 16px
$text-normal-lh: map-get(map-get($text-styles, 'normal'), 'line-height'); // 1.5
$text-normal-fw: map-get(map-get($text-styles, 'normal'), 'font-weight'); // 400

$text-x-normal-fs: map-get(map-get($text-styles, 'x-normal'), 'font-size'); // 18px
$text-x-normal-lh: map-get(map-get($text-styles, 'x-normal'), 'line-height'); // 1.5
$text-x-normal-fw: map-get(map-get($text-styles, 'x-normal'), 'font-weight'); // 400

$text-large-fs: map-get(map-get($text-styles, 'large'), 'font-size'); // 20px
$text-large-lh: map-get(map-get($text-styles, 'large'), 'line-height'); // 1.5
$text-large-fw: map-get(map-get($text-styles, 'large'), 'font-weight'); // 400

$text-x-large-fs: map-get(map-get($text-styles, 'x-large'), 'font-size'); // 24px
$text-x-large-lh: map-get(map-get($text-styles, 'x-large'), 'line-height'); // 1.5
$text-x-large-fw: map-get(map-get($text-styles, 'x-large'), 'font-weight'); // 400

$text-2x-large-fs: map-get(map-get($text-styles, '2x-large'), 'font-size'); // 28px
$text-2x-large-lh: map-get(map-get($text-styles, '2x-large'), 'line-height'); // 26
$text-2x-large-fw: map-get(map-get($text-styles, '2x-large'), 'font-weight'); // 400

// Title styles
$title-very-small-fs: map-get(map-get($title-styles, 'very-small'), 'font-size'); // 14px
$title-very-small-lh: map-get(map-get($title-styles, 'very-small'), 'line-height'); // 1.5
$title-very-small-fw: map-get(map-get($title-styles, 'very-small'), 'font-weight'); // 700

$title-small-fs: map-get(map-get($title-styles, 'small'), 'font-size'); // 16px
$title-small-lh: map-get(map-get($title-styles, 'small'), 'line-height'); // 1.5
$title-small-fw: map-get(map-get($title-styles, 'small'), 'font-weight'); // 700

$title-normal-fs: map-get(map-get($title-styles, 'normal'), 'font-size'); // 24px
$title-normal-lh: map-get(map-get($title-styles, 'normal'), 'line-height'); // 1.5
$title-normal-fw: map-get(map-get($title-styles, 'normal'), 'font-weight'); // 700

$title-normal-bold-fs: map-get(map-get($title-styles, 'normal-bold'), 'font-size'); // 24px
$title-normal-bold-lh: map-get(map-get($title-styles, 'normal-bold'), 'line-height'); // 1.5
$title-normal-bold-fw: map-get(map-get($title-styles, 'normal-bold'), 'font-weight'); // 500

$title-large-fs: map-get(map-get($title-styles, 'large'), 'font-size'); // 32px
$title-large-lh: map-get(map-get($title-styles, 'large'), 'line-height'); // 1.5
$title-large-fw: map-get(map-get($title-styles, 'large'), 'font-weight'); // 700

$title-x-large-fs: map-get(map-get($title-styles, 'x-large'), 'font-size'); // 48px
$title-x-large-lh: map-get(map-get($title-styles, 'x-large'), 'line-height'); // 1.5
$title-x-large-fw: map-get(map-get($title-styles, 'x-large'), 'font-weight'); // 700

$font-weight-500: 500;

//Màu sắc cho chủ đề mặc định (chủ đề sáng)
:root {
  --primary-color: #0e57b7; // Màu chính cho chủ đề sáng
  --background-color: #ffffff; // Màu nền cho chủ đề sáng
  --text-color: #000000; // Màu văn bản cho chủ đề sáng
  --secondary-color: #f0f0f0; // Màu phụ cho chủ đề sáng
  --border-color: #e8e8e8; // Màu viền cho chủ đề sáng
  --hover-color: #e6f7ff; // Màu khi rê chuột lên cho chủ đề sáng
  --gray-color: gray;
  --background-iphone-popup-coler:#a6bab8;
  --transparent-color:transparent;
  --title-color:#0870d9;
  --background-banner-color: #F4F7FF;
  --bgr-list-btn-color: #F2F5FF;
  --unactive-button-color:#d6d6d6;
  --primary-button-color:#262FED;
  --stop-receiving-color:#AA8934;
  --error-btn-color:#FF1001;
  --success-btn-color:#34AA44;
  --step-line-booking:#1890ff;
  --step-line-booking-opacity30:#1890ff30;
  --float-btn-color:#4b7fed;
  --attention-color:#0039e3;
  --light-black-color: #333;
  --sunrise-yellow-color:$--sunrise-yellow-6;
  --bgr-review-color:#F3F3F5;
  --blue-ribbon: #0068FF;
  --gray-solitude: #E8F0FE;
  --gray-alto: #DDDDDD;
  --gray-silver: #C4C4C4;
  --gray-gallery: #EDEDED;
  --gray-mercury: #E4E4E4;
  --gray-mid-gray: #686770;
  --gray-athens: #F9F9FB;
  --gray-zircon: #F4F7FF;
  --blue-deep-cove: #040747;
  // ...Thêm biến sáng 
}

[data-theme='DKON'] {
  --primary-color: #d20043; // Màu chính cho chủ đề sáng
  --background-color: #ffffff; // Màu nền cho chủ đề sáng
  --text-color: #000000; // Màu văn bản cho chủ đề sáng
  --secondary-color: #f0f0f0; // Màu phụ cho chủ đề sáng
  --border-color: #e8e8e8; // Màu viền cho chủ đề sáng
  --hover-color: #e6f7ff; // Màu khi rê chuột lên cho chủ đề sáng
  --gray-color: gray;
  --background-iphone-popup-coler:#a6bab8;
  --transparent-color:transparent;
  --title-color:#c50215;
  --background-banner-color: #F4F7FF;
  --bgr-list-btn-color: #F2F5FF;
  --unactive-button-color:#d6d6d6;
  --primary-button-color:#CD2226;
  --stop-receiving-color:#AA8934;
  --error-btn-color:#FF1001;
  --success-btn-color:#34AA44;
  --step-line-booking:rgb(255, 34, 86);
  --float-btn-color:#f43b3e;
  --attention-color:#dd0030;
  --sunrise-yellow-color: $--sunrise-yellow-6;
  // ...Thêm biến
}
[data-theme='VIPMEMBER'] {
  --primary-color: #d20043; // Màu chính cho chủ đề vipmember
  --background-color: #222222; // Màu nền cho chủ đề vipmenber
  --text-color: #EACDA6; // Màu văn bản cho chủ đề vipmember
  --secondary-color: #a2c71c; // Màu phụ cho chủ đề vipmember
  --border-color: #EACDA6; // Màu viền cho chủ đề vipmember
  --hover-color: #e6f7ff; // Màu khi rê chuột lên cho chủ đề vipmember
  --gray-color: gray;
  --background-iphone-popup-coler:#a6bab8;
  --transparent-color:transparent;
  --title-color:#c50215;
  --background-banner-color: #222222;
  --bgr-list-btn-color: #222222;
  --unactive-button-color:#FFF8E1;
  --primary-button-color:#EACDA6;
  --stop-receiving-color:#AA8934;
  --error-btn-color:#FF1001;
  --success-btn-color:#34AA44;
  --step-line-booking:rgb(255, 34, 86);
  --float-btn-color:#EACDA6;
  --attention-color:#dd0030;
  --sunrise-yellow-color: $--sunrise-yellow-6;
  --blue-deep-cove:#EACDA6;
  --title-color:#EACDA6;
  --card-item-color:#424041;
  --text-very-small:#fff;
  --menu-mobile-color:linear-gradient(90deg, #0f0e0d 0%, #271e0e 100%);
  --background-icon-color: #EACDA6;
  --gray-silver: #C4C4C4;
}
// Màu sắc cho chủ đề tối
body.dark {
  --primary-color: #0e57b7; // Màu chính cho chủ đề tối
  --background-color: #121212; // Màu nền cho chủ đề tối
  --text-color: #ffffff; // Màu văn bản cho chủ đề tối
  --secondary-color: #262626; // Màu phụ cho chủ đề tối
  --border-color: #3a3a3a; // Màu viền cho chủ đề tối
  --hover-color: #2f2f2f; // Màu khi rê chuột lên cho chủ đề tối,
  --gray-color: gray;
  // ...Thêm biến Tối
}