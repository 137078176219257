.SearchList {
  position: relative;

  & .ant-input-group .ant-input {
    padding: 13px 17px;
    width: 100%;
    border-right-width: 1px;
    z-index: 1;
    border-radius: 6px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 48px !important;
  }

  &.ant-input::placeholder {
    font-size: 14px;
  }

  & .ant-input-search-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    z-index: 99;
  }

  & svg {
    color: #e5e5e5;
  }
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 1 !important;
}